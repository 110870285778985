import { useRouter } from "next/router";
import Link from "next/link";
import { useEffect } from "react";
import LocalesData from './locales/data';

const LocaleLinks = ({ isTitle = true }) => {
  const { locale, locales, pathname } = useRouter();

  useEffect(() => {
    localStorage.setItem('locale', locale);
  }, [locale]);

  return (
    <div className="text-center min-w-[270px]">
      {isTitle && (
        <p className="uppercase opacity-50 tracking-wider">{LocalesData[locale]}</p>
      )}
      <p className="py-0 divide-x divide-opacity-50 divide-white">
        {locales.map((item) => (
          <Link href={pathname} locale={item} key={item} scroll={false} >
            <a className="text-lg uppercase px-4 font-medium opacity-80 hover:opacity-100 hover:text-secondary">{item}</a>
          </Link>
        ))}
      </p>
    </div>
  )
};

export default LocaleLinks;